import React from 'react';
import UserSelection from './UserSelection';
import LoginCards from '../../../common/LoginCards';

const SsoLoginView = () => {
    return (
        <>
            <UserSelection />
            <LoginCards />
        </>
    );
};

export default SsoLoginView;
