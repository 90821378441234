import React, { useState, useEffect } from 'react';
import { NoSessionApi } from '../Api';
import { lang } from '../i18n';

interface News {
    title: string;
    subtitle: string;
    body: string;
    footer: string;
}

const NewsCard = () => {
    const [news, setNews] = useState<News>();
    const [info, setInfo] = useState('');

    const fetchNews = () =>
        NoSessionApi.get('News', 'latest')
            .then(result => {
                setNews(result.data);
            })
            .catch(() => setInfo(lang('login.news_card.no_news.info')));

    useEffect(() => {
        fetchNews();
    }, []);

    return (
        <>
            {!!news && (
                <div className="card fixed-height-card">
                    <div className="card-header">
                        <h4 dangerouslySetInnerHTML={{ __html: news.title }} />
                        <h5 className="card-subtitle" dangerouslySetInnerHTML={{ __html: news.subtitle }} />
                    </div>
                    <div className="card-body flex-fill" dangerouslySetInnerHTML={{ __html: news.body }} />
                    <div className="card-footer" dangerouslySetInnerHTML={{ __html: news.footer }} />
                </div>
            )}
            {!!info && <div>{info}</div>}
        </>
    );
};

export default NewsCard;
