import React from 'react';
import { lang } from '../i18n';

const NeedHelp = () => {
    return (
        <div className="card fixed-height-card">
            <div className="card-header">
                <h4>{lang('login.help_card.title')}</h4>
            </div>

            <div className="card-body flex-fill">
                <ul className="link-list">
                    <li>
                        <a
                            className="ld_link"
                            href="http://support.leaddesk.com/hc/en-us/categories/200148706-Agent-Manual-LeadDesk-Software"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {lang('login.help_card.agent_manual.label')}
                        </a>
                    </li>
                    <li>
                        <a
                            className="ld_link"
                            href="http://support.leaddesk.com/hc/en-us/categories/201468683"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {lang('login.help_card.admin_manual.label')}
                        </a>
                    </li>
                    <li>
                        <a
                            className="ld_link"
                            href="http://support.leaddesk.com/hc/en-us/categories/200141417-Useful-information"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {lang('login.help_card.popular_support_topics.label')}
                        </a>
                    </li>
                    <li>
                        <a className="ld_link" href="http://status.leaddesk.com" target="_blank" rel="noopener noreferrer">
                            {lang('login.help_card.leaddesk_service_status.label')}
                        </a>
                    </li>
                </ul>
            </div>

            <div className="card-footer">
                {lang('login.help_card.footer.title')}:
                <br />
                <a className="ld_link" href="http://support.leaddesk.com" target="_blank" rel="noopener noreferrer">
                    support.leaddesk.com
                </a>
            </div>
        </div>
    );
};

export default NeedHelp;
