import React, { useState, ChangeEvent, FormEvent } from 'react';
import { Button } from '@rmwc/button';
import { EMAIL_REGEX, showApiError } from '../../utils/helpers';
import { NoSessionApi } from '../Api';
import { lang } from '../i18n';

const Newsletter = () => {
    const [email, setEmail] = useState('');
    const [subscription, setSubscription] = useState(false);
    const [error, setError] = useState('');

    const signUp = async () => {
        try {
            await NoSessionApi.postUrl('Hubspot', 'subscribe_newsletter', { email });
            setSubscription(true);
        } catch (err) {
            showApiError(err);
        }
    };

    const handleEmailValidation = emailStr => {
        if (emailStr) {
            if (!EMAIL_REGEX.test(emailStr)) {
                setError('Invalid email address');
            } else {
                setError('');
            }
        }
    };
    return (
        <div className="card fixed-height-card">
            {!subscription && (
                <>
                    <div className="card-header">
                        <h4>{lang('login.newsletter_card.title')}</h4>
                    </div>

                    <div className="card-body flex-fill">
                        <div>{lang('login.newsletter_card.info')}</div>
                        <div className="newsletter-signup-form">
                            <input
                                onChange={(evt: ChangeEvent<HTMLInputElement> & FormEvent) => {
                                    setEmail(evt.target.value);
                                    handleEmailValidation(evt.target.value);
                                }}
                                className="underline-input"
                                type="email"
                                placeholder={lang('generic.email_input.label')}
                                required
                            />
                            {!!error && <div className="ld_error">{error}</div>}
                            <Button raised className="save button-secondary" onClick={signUp} disabled={!(email && !error)}>
                                {lang('generic.sign_up.button')}
                            </Button>
                        </div>
                    </div>
                </>
            )}
            {subscription && <div>{lang('login.newsletter_signup_thank_you.label')}</div>}
        </div>
    );
};

export default Newsletter;
