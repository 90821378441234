import React, { useEffect, useState, ChangeEvent, FormEvent } from 'react';
import { Button } from '@rmwc/button';
import { TextField } from '@rmwc/textfield';
import { Checkbox } from '@rmwc/checkbox';
import classNames from 'classnames';

interface NewPasswordProps {
    username?: string;
    title?: string;
    description?: string;
    regexes: { minLength: string; letters: string; digits: string };
    weakPasswords: { other?: [] };
    forgotPassword?: boolean;
    minLength: number;
    serverErrors?: { currentPassword: string; newPassword: string; generic: string };
    translate: (key: string) => string;
    handleClose?: () => any;
    updatePassword: (currentPassword: string, newPassword: string) => any;
}

enum PasswordStrength {
    Weak = 'generic.change_password.strength.weak.label',
    Medium = 'generic.change_password.strength.medium.label',
    Good = 'generic.change_password.strength.good.label',
    Perfect = 'generic.change_password.strength.perfect.label',
}

export const ChangePasswordForm = (props: NewPasswordProps) => {
    const {
        title = '',
        username = '',
        description = '',
        regexes,
        weakPasswords,
        forgotPassword,
        minLength = 8,
        serverErrors,
        translate,
        handleClose,
        updatePassword,
    } = props;

    const [currentPassword, setCurrentPassword] = useState<string>('');
    const [newPassword, setNewPassword] = useState<string>('');
    const [hideCurrentPassword, setHideCurrentPassword] = useState<boolean>(true);
    const [hideNewPassword, setHideNewPassword] = useState<boolean>(true);
    const [errors, setErrors] = useState(serverErrors || { currentPassword: '', newPassword: '', generic: '' });
    const [passwordStrength, setPasswordStrength] = useState<PasswordStrength | string>('');

    const defaultValidationRules = {
        minLength: false,
        letters: false,
        digits: false,
        specialCriteria: false,
    };

    const [passwordValidations, setPasswordValidations] = useState(defaultValidationRules);
    const [strongPassword, setStrongPassword] = useState(false);

    // update the password strength when password changes
    useEffect(
        () => {
            const count = Object.values(passwordValidations).filter(item => item === true).length;
            if (count == 0) {
                setPasswordStrength('');
            } else if (count == 1) {
                setPasswordStrength(PasswordStrength.Weak);
            } else if (count == 2) {
                setPasswordStrength(PasswordStrength.Medium);
            } else if (count == 3 && !strongPassword) {
                // even if three conditions are met, if password is not strong then the strength is still medium
                setPasswordStrength(PasswordStrength.Medium);
            } else if (count == 3 && strongPassword) {
                setPasswordStrength(PasswordStrength.Good);
            } else if (count == 4 && !strongPassword) {
                // even if four conditions are met, if password is not strong then the strength is still medium
                setPasswordStrength(PasswordStrength.Medium);
            } else if (count == 4 && strongPassword) {
                setPasswordStrength(PasswordStrength.Perfect);
            }
        },
        [passwordValidations]
    );

    useEffect(
        () => {
            setErrors(serverErrors || { currentPassword: '', newPassword: '', generic: '' });
        },
        [serverErrors]
    );

    const checkSpecialCriteria = pwd => {
        // special criteria for perfect password is to satisfy 2 of following -> special character, 2 numbers, 2 uppercase and 2 lowercase, more than 12 characters
        const specialCriteriaRegexes = ['(?=.{13,}$)', '(?=.*[a-z].*[a-z])(?=.*[A-Z].*[A-Z])', '(\\D*\\d){2,}', '(?=(.*[\\W]){1,})'];
        const conditionsMet = specialCriteriaRegexes.map(test => new RegExp(test).test(pwd));
        if (conditionsMet.filter(item => item === true).length >= 2) {
            return true;
        } else {
            return false;
        }
    };

    const passwordValidationsWithRegex = (pwd: string) => {
        const hasMinimumlength = regexes.minLength && new RegExp(regexes.minLength).test(pwd);
        const hasAtleastOneCapAndSmallLetter = regexes.letters && new RegExp(regexes.letters).test(pwd);
        const hasAtleastOneDigit = regexes.digits && new RegExp(regexes.digits).test(pwd);
        const metSpecialCriteria = hasMinimumlength && hasAtleastOneCapAndSmallLetter && hasAtleastOneDigit && checkSpecialCriteria(pwd);
        setPasswordValidations(defaultValidationRules);
        setPasswordValidations({
            minLength: hasMinimumlength,
            letters: hasAtleastOneCapAndSmallLetter,
            digits: hasAtleastOneDigit,
            specialCriteria: metSpecialCriteria,
        });
    };

    const weakPasswordCheck = (pwd: string) => {
        let filteredWeakPasswords = [];
        if (pwd.length < 12) {
            filteredWeakPasswords = weakPasswords[pwd.length] || [];
        } else {
            filteredWeakPasswords = weakPasswords.other || [];
        }
        const strongPassword = !filteredWeakPasswords.map(pwd => pwd.toLowerCase()).includes(pwd.toLowerCase());
        setStrongPassword(strongPassword);
        !strongPassword && setErrors({ ...errors, newPassword: translate('generic.password_validation.too_weak.error') });
    };

    const basicPasswordValidation = (pwd: string) => {
        setErrors({ currentPassword: errors.currentPassword, newPassword: '', generic: '' });
        if (username && pwd.toLowerCase().indexOf(username.toLowerCase()) !== -1) {
            setErrors({ ...errors, newPassword: translate('generic.password_validation.contains_username.error') });
        } else if (!forgotPassword && currentPassword && pwd === currentPassword) {
            setErrors({ ...errors, newPassword: translate('generic.password_validation.no_change.error') });
        }
    };

    const validatePassword = (pwd: string) => {
        basicPasswordValidation(pwd);
        weakPasswordCheck(pwd);
        passwordValidationsWithRegex(pwd);
    };

    const isUpdateButtonDisabled =
        !!errors.newPassword ||
        !!errors.currentPassword ||
        (passwordStrength !== PasswordStrength.Good && passwordStrength !== PasswordStrength.Perfect);

    return (
        <div className="change-password">
            <div className={classNames('title-text', !forgotPassword ? 'MuiDialogTitle-root' : 'ld_h5')}>
                {title ? title : translate('label_change_password')}
            </div>
            <div className={classNames(!forgotPassword ? 'MuiDialogContent-root' : 'content-root')}>
                {description && <div className="description ld_Body2">{description}</div>}
                {!!errors['generic'] && <div className="error">{errors['generic']}</div>}
                {!forgotPassword && (
                    <div className="settings-item-with-label">
                        <label>{translate('generic.change_password.current_password.label')}</label>
                        <TextField
                            className="ld_Body2"
                            outlined
                            type={hideCurrentPassword ? 'password' : 'text'}
                            trailingIcon={{
                                icon: hideCurrentPassword ? 'visibility' : 'visibility_off',
                                onClick: () => setHideCurrentPassword(!hideCurrentPassword),
                                className: 'password_visibility_icon',
                            }}
                            onChange={(event: ChangeEvent<HTMLInputElement> & FormEvent) => {
                                setCurrentPassword(event.currentTarget.value);
                                setErrors({ ...errors, currentPassword: '' });
                            }}
                            value={currentPassword}
                        />
                        {!!errors['currentPassword'] && <div className="error-info">{errors['currentPassword']}</div>}
                    </div>
                )}

                <div className="settings-item-with-label">
                    <label className="ld_Body2">{translate('generic.change_password.new_password.label')}</label>
                    <TextField
                        outlined
                        type={hideNewPassword ? 'password' : 'text'}
                        trailingIcon={{
                            icon: hideNewPassword ? 'visibility' : 'visibility_off',
                            onClick: () => setHideNewPassword(!hideNewPassword),
                            className: 'password_visibility_icon',
                        }}
                        onChange={(event: ChangeEvent<HTMLInputElement> & FormEvent) => {
                            setNewPassword(event.currentTarget.value);
                            validatePassword(event.currentTarget.value);
                        }}
                        value={newPassword}
                    />
                    {!!errors['newPassword'] && <div className="error-info">{errors['newPassword']}</div>}
                </div>

                <div className="password-strength">
                    {translate('generic.change_password.strength.label')}:
                    <div className="value">&nbsp;{passwordStrength && translate(passwordStrength)}</div>
                </div>

                <div className={`password-strength-status ${passwordStrength.split('.')[3]}`}>
                    <hr />
                    <hr />
                    <hr />
                    <hr />
                </div>

                <Checkbox
                    checked={passwordValidations['minLength']}
                    label={translate('generic.change_password.validation_rules.min_length.info').replace(
                        '({minLength})',
                        minLength.toString()
                    )}
                />
                <Checkbox
                    checked={passwordValidations['letters']}
                    label={translate('generic.change_password.validation_rules.letters.info')}
                />
                <Checkbox
                    checked={passwordValidations['digits']}
                    label={translate('generic.change_password.validation_rules.digits.info')}
                />
            </div>
            <div className={classNames(!forgotPassword ? 'MuiDialogActions-root' : '')}>
                {!forgotPassword && <Button onClick={handleClose}>{translate('generic.cancel.button')}</Button>}
                <Button
                    raised
                    className="save"
                    onClick={() => updatePassword(currentPassword, newPassword)}
                    disabled={isUpdateButtonDisabled}
                >
                    {translate('generic.action.update.button')}
                </Button>
            </div>
        </div>
    );
};

export default ChangePasswordForm;
