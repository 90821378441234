import React from 'react';
import NeedHelp from './NeedHelp';
import Newsletter from './Newsletter';
import NewsCard from './NewsCard';

const LoginCards = () => {
    return (
        <div className="login-cards">
            <NeedHelp />
            <NewsCard />
            <Newsletter />
        </div>
    );
};

export default LoginCards;
