import { createBrowserHistory, Location } from 'history';
import { setInterval } from 'worker-timers';
import { useEffect, useRef } from 'react';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

export const history = createBrowserHistory();

export const getDateTimeFormatString = () => {
    return (window as any).UserInfo.datePatternPhp.toUpperCase() + ' ' + (window as any).UserInfo.timePatternPhp;
};

export const getDateFormatString = () => {
    return (window as any).UserInfo.datePatternPhp.toUpperCase();
};

export const secondsToTime = (timeInSeconds: number) => {
    const pad = (num: number | string, size: number) =>
        (num as number) >= 100 || (num as string).length >= 3 ? num : ('000' + num).slice(size * -1);
    const time = parseFloat(timeInSeconds.toString());
    const days = Math.floor(time / 60 / 60 / 24);
    const hours = Math.floor(time / 60 / 60) % 24;
    const minutes = Math.floor(time / 60) % 60;
    const seconds = Math.floor(time - (minutes * 60 + hours * 3600 + days * 86400));

    return {
        days: pad(days, 2),
        hours: pad(hours, 2),
        minutes: pad(minutes, 2),
        seconds: pad(seconds, 2),
    };
};

export const secondsToHms = (timeInSeconds: number) => {
    const timeValues = secondsToTime(timeInSeconds);
    const { days, hours, seconds, minutes } = timeValues;

    const dDisplay = days > 0 ? `${days}d` : '';
    const hDisplay = hours > 0 ? `${hours}h` : '';
    const mDisplay = minutes > 0 ? `${minutes}m` : '';
    const sDisplay = seconds > 0 ? `${seconds}s` : '';

    return dDisplay + hDisplay + mDisplay + (dDisplay ? '' : hours > 10 ? '' : sDisplay);
};

export const replaceUrlVars = (urlVariableValues: object, url: string): string => {
    Object.keys(urlVariableValues).forEach(variableName => {
        const value = urlVariableValues[variableName];
        const regexp = new RegExp('{' + variableName + '}', 'g');
        url = url.replace(regexp, encodeURIComponent(value));
    });
    const unrecognizedTags = new RegExp('{[^}]*?}', 'g');
    url = url.replace(unrecognizedTags, '');
    return url;
};

export function useInterval(callback: () => any, delay: number) {
    const savedCallback = useRef<any>();

    // Remember the latest callback.
    useEffect(
        () => {
            savedCallback.current = callback;
        },
        [callback]
    );

    // Set up the interval.
    useEffect(
        () => {
            function tick() {
                savedCallback.current();
            }
            if (delay !== null) {
                const id = setInterval(tick, delay);
                return () => clearInterval(id);
            }
        },
        [delay]
    );
}

export function formatBytes(num) {
    if (num < 1024) {
        return num + 'B';
    } else if (num >= 1024 && num < 1048576) {
        return (num / 1024).toFixed(1) + 'KB';
    } else if (num >= 1048576) {
        return (num / 1048576).toFixed(1) + 'MB';
    }
}

export const formatPhoneNumber = (phoneNumber: string) => {
    const phone = parsePhoneNumberFromString(phoneNumber);
    return phone ? phone.formatInternational() : phoneNumber;
};

/**
 * Convert an image url to a base64 string
 *
 * Credit: https://gist.github.com/oliyh/db3d1a582aefe6d8fee9
 *
 * @param {String} url
 */
export const convertImgToBase64 = (url: string): Promise<string> => {
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.onload = () => {
            const reader = new FileReader();
            reader.onloadend = () => {
                resolve(reader.result as string);
            };
            reader.onerror = () => reject();
            reader.readAsDataURL(xhr.response);
        };
        xhr.onerror = () => reject();
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.send();
    });
};

/**
 * Convert object into encoded URL parameters e.g. {a:1,b:2} => "a=1&b=2"
 * Alternative to $.param function
 */
export function urlParams(params: object): string {
    return Object.keys(params)
        .map(key => {
            return encodeURIComponent(key) + '=' + encodeURIComponent(params[key]);
        })
        .join('&');
}

/*
 * Load image url into img tags in HTML based on `cid` data.
 *
 * Also, ensure `image-blot` class name in order for Quill to render the img.
 * @param htmlContent
 * @param media
 */
export const loadImgDataToHTML = (htmlContent: string, media: { filename: string; url: string; size: number; cid: string }[]): string => {
    let htmlResult = htmlContent;
    // load image url to img tag
    media.forEach(attachment => {
        if (attachment.cid) {
            htmlResult = htmlResult.replace(`"cid:${attachment.cid}"`, `"${attachment.url}" data-cid="${attachment.cid}"`);
        }
    });

    // ensure that all img element has `image-blot` in className
    // so that Quill can render them with ImageBlot
    const template = document.createElement('template');
    template.innerHTML = htmlResult;
    const imgElements = template.content.querySelectorAll('img');
    imgElements.forEach(element => {
        if (!element.className.includes('image-blot')) {
            element.className = element.className + ' image-blot';
        }
    });
    return template.innerHTML;
};

/**
 * Replace formatting of html from email clients with Quill's formatting
 */
export const normalizeEmailHTML = (htmlContent: string, reserveWhiteSpace?: boolean) => {
    let result = htmlContent;
    if (!reserveWhiteSpace) {
        result = result.replace(/((\r?\n|\r)|<p><br><\/p>|<span><br><\/span>)/gm, '');
    }
    // font size from Outlook
    result = result
        .replace(/font-size: 8pt/gm, 'font-size: 10px')
        .replace(/font-size: 14pt/gm, 'font-size: 14px')
        .replace(/font-size: 24pt/gm, 'font-size: 18px');

    // font size from Gmail
    result = result
        .replace(/<font size="1">/gm, '<span style="font-size: 10px">')
        .replace(/<font size="4">/gm, '<span style="font-size: 18px">')
        .replace(/<\/font>/gm, '</span>');

    // add attribute `target="_blank"` to links
    const template = document.createElement('template');
    template.innerHTML = result;
    const linkElements = template.content.querySelectorAll('a[href]');
    linkElements.forEach(element => {
        element.setAttribute('target', '_blank');
    });
    result = template.innerHTML;

    return result;
};

export const getViewUrl = (view: string, location: Location) => {
    const urlParams = new URLSearchParams(location.search);
    urlParams.set('view', view);
    return location.pathname + `?${urlParams.toString()}`;
};

/**
 * Basic regex to validate emails. This regex only helps to prevent typos from user.
 * Strict email validation should always be handled in the server.
 */
export const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

/**
 * Download binary from a URL using iframe instead of `window.open`
 * to avoid triggering the `onbeforeunload` event.
 * @param downloadUrl The url to download file
 */
export const downloadFromUrl = (downloadUrl: string) => {
    let downloadIframe = document.querySelector('#leaddesk-download-iframe');
    if (!downloadIframe) {
        downloadIframe = document.createElement('iframe');
        downloadIframe.setAttribute('id', 'leaddesk-download-iframe');
        downloadIframe.setAttribute('style', 'display: none');
        document.body.appendChild(downloadIframe);
    }
    downloadIframe.setAttribute('src', downloadUrl);
};

/**
 * Render contact name based on first and last name.
 * @param firstName contact's first name
 * @param lastName contact's last name
 * @param fallbackText Text to display if contact has no name. Default value: "Unknown" in English.
 */
export const renderContactName = (firstName: string, lastName: string, fallbackText?: string) => {
    return (firstName && firstName.length) || (lastName && lastName.length) ? `${firstName || ''} ${lastName || ''}` : fallbackText;
};

export const showApiError = err => window.ShowError((err.response && err.response.data.description) || err.message);
