import React from 'react';
import ReactDOM from 'react-dom';
import LoginCards from '../LoginCards';
import ThemeComponent from '../ThemeComponent';
import lang from '../../utils/intl';
import ChangePassword from './ChangePassword';

export const showChangePasswordDialog = async props => {
    return new Promise(resolve => {
        var isAlreadyOpened = document.getElementById('change-password-dialog-root');
        if (!isAlreadyOpened) {
            const dialogRoot = document.createElement('div');
            dialogRoot.id = 'change-password-dialog-root';
            document.body.append(dialogRoot);
            ReactDOM.render(
                <ThemeComponent>
                    <ChangePassword
                        translate={lang}
                        onClose={(updated?) => {
                            dialogRoot.remove();
                            resolve(updated);
                        }}
                        {...props}
                    />
                </ThemeComponent>,
                dialogRoot
            );
        }
    });
};

export const showChangePasswordForm = async (containerId, props) => {
    return new Promise(resolve => {
        ReactDOM.render(
            <ThemeComponent>
                <ChangePassword
                    translate={lang}
                    onClose={(updated?) => {
                        resolve(updated);
                    }}
                    {...props}
                />
                <LoginCards />
            </ThemeComponent>,
            document.getElementById(containerId)
        );
    });
};
