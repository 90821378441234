import { executeExternalAppsIfMeta } from './externalApps';
import { showResetPasswordForm } from './resetPassword';
import './styles/outside.scss';
import { UserSelectionView } from './components/SsoLogin';
import { executeExpiredPasswordIfMeta } from './expiredPassword';

/**
 * Outside bundle is intended to contain all code needed to run and render pages outside of user session.
 * Mainly login page and all similar pages where user it not yet fully authenticated.
 */

/**
 * Bundle's side effects
 */
executeExternalAppsIfMeta();
executeExpiredPasswordIfMeta();

/**
 * Bundle's published code/functions
 *
 * Let's keep this to a minimum and prefer to have all code inside the bundle
 */
(window as any).Outside = {
    showResetPasswordForm,
    Components: {
        UserSelectionView,
    },
};
